$(function () {
    $('#confetti').hide();


    var canvas = $("#canvas")[0],
        context = canvas.getContext('2d'),
        gameLoop,
        min = -5,
        max = 5,
        // PADDLE
        paddleW = 100,
        paddleH = 5,
        paddleY = 550,
        paddleX = canvas.width / 2 - paddleW / 2,
        paddleDeltaX = 0,
        paddleDeltaY = 0,
        paddleDirection = "NONE",
        paddleSpeed = 10,
        paddleColor = "#14b9d5",
        // BALL
        ballDeltaX = 0,
        ballDeltaY = 0,
        ballRadius = 10,
        ballX = (canvas.width / 2 - ballRadius / 2) + Math.random() * (min - max) + min,
        ballY = (canvas.height / 2 - ballRadius / 2) - (canvas.height - paddleY) + Math.random() * (min - max) + min,
        ballColor = "white",
        bricks = [],
        // BRICK
        brickPerRow = 11,
        brickX = 0,
        brickY = 0,
        brickH = 30,
        brickW = canvas.width / brickPerRow,
        brickColor = "#14b9d5",
        nbBrick = 0,
        bumpedX = false,
        bumpedY = false,
        isStarted = false,
        isFinished = false,
        errorCode;

    errorCode = setInterval(function () {
        if (error != null) {
            selectBricksLayout(error);
            createBricks();
            clearInterval(errorCode);
        }
    }, 20)

    function selectBricksLayout(e) {
        switch (e) {
            case 403:
                bricks = [
                      [1, 0, 1, 0, 1, 1, 1, 0, 1, 1, 1],
                      [1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1],
                      [1, 1, 1, 0, 1, 0, 1, 0, 1, 1, 1],
                      [0, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1],
                      [0, 0, 1, 0, 1, 1, 1, 0, 1, 1, 1]
                  ];
                break;
            case 404:
                bricks = [
                      [1, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1],
                      [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1],
                      [1, 1, 1, 0, 1, 0, 1, 0, 1, 1, 1],
                      [0, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1],
                      [0, 0, 1, 0, 1, 1, 1, 0, 0, 0, 1]
                  ];
                break;
            case 500:
                bricks = [
                      [1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1],
                      [1, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1],
                      [1, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1],
                      [0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1],
                      [1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1]

                  ];
                break;
            default:
                bricks = [
                      [1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1, ],
                      [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, ],
                      [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, ],
                      [0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, ],
                      [0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, ],
                      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
                      [0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, ]
                  ];
        }
        return bricks;
    }

    function drawPaddle() {
        context.fillStyle = paddleColor;
        context.fillRect(paddleX, paddleY, paddleW, paddleH);
        if (paddleY != 450) {
            paddleY -= 5;
        }
    }

    function drawBall() {
        context.beginPath();
        context.arc(ballX, ballY, ballRadius, 0, Math.PI * 2, true);
        context.fillStyle = ballColor;
        context.fill();
    }

    function createBricks() {
        for (var i = 0; i < bricks.length; i++) {
            for (var j = 0; j < bricks[i].length; j++) {
                drawBrick(j, i, bricks[i][j]);
            }
        }
    }

    function drawBrick(x, y, type) {
        switch (type) {
            case 1:
                context.fillStyle = brickColor;
                context.strokeStyle = brickColor;
                break;
            default:
                context.clearRect(x * brickW, y * brickH, brickW, brickH);
                break;
        }
        if (type) {
            context.fillRect(x * brickW, y * brickH, brickW, brickH);
            context.strokeRect(x * brickW, y * brickH, brickW, brickH);
        }
    }

    function animate() {
        context.clearRect(0, 0, canvas.width, canvas.height);

        createBricks();
        moveBall();
        movePaddle();
        drawBall();
        drawPaddle();
    }

    function start() {
        isStarted = true;
        ballDeltaY = -6;
        ballDeltaX = -3;
        selectBricksLayout(error)

        gameLoop = setInterval(animate, 20);

        $(document).keydown(function (evt) {
            if (evt.keyCode == 39) {
                paddleDirection = 'RIGHT';
            } else if (evt.keyCode == 37) {
                paddleDirection = 'LEFT';
            }
        });

        $(document).keyup(function (evt) {
            if (evt.keyCode == 39) {
                paddleDirection = 'NONE';
            } else if (evt.keyCode == 37) {
                paddleDirection = 'NONE';
            }
        });
    }

    function endGame(result) {
        isStarted = false;
        clearInterval(gameLoop);
        if (result == 'win') {
            $('#confetti').fadeIn(1000);
        } else if (result == 'lose') {
            $('#game p').fadeIn(1500);

        }
        ballDeltaY = 0;
        ballDeltaX = 0;
        bricks = selectBricksLayout(error);

    }

    function moveBall() {
        //if ball hit on Y
        if (ballY + ballDeltaY - ballRadius < 0 ||
            collisionYWithBricks()) {
            ballDeltaY = -ballDeltaY;
        }

        //if ball hit on X
        if ((ballX + ballDeltaX - ballRadius < 0) ||
            (ballX + ballDeltaX + ballRadius > canvas.width) ||
            collisionXWithBricks()) {
            ballDeltaX = -ballDeltaX;
        }

        //if ball fall down
        if (ballY + ballDeltaY + ballRadius > canvas.height) {
            endGame('lose');
        }


        if (ballY + ballDeltaY + ballRadius >= paddleY) {
            if (ballX + ballDeltaX >= paddleX &&
                ballX + ballDeltaX <= paddleX + paddleW) {
                ballDeltaY = -ballDeltaY;
            }
        }
        ballX = ballX + ballDeltaX;
        ballY = ballY + ballDeltaY;
    }

    function movePaddle() {
        if (paddleDirection == 'LEFT') {
            paddleDeltaX = -paddleSpeed;
        } else if (paddleDirection == 'RIGHT') {
            paddleDeltaX = paddleSpeed;
        } else {
            paddleDeltaX = 0;
        }
        if (paddleX + paddleDeltaX < 0 || paddleX + paddleDeltaX + paddleW > canvas.width) {
            paddleDeltaX = 0;
        }
        paddleX = paddleX + paddleDeltaX;
    }

    function collisionXWithBricks() {
        bumpedX = false;
        for (var i = 0; i < bricks.length; i++) {
            for (var j = 0; j < bricks[i].length; j++) {
                if (bricks[i][j]) { // if brick is still visible
                    brickX = j * brickW;
                    brickY = i * brickH;
                    if (
                        // barely touching from left
                        ((ballX + ballDeltaX + ballRadius >= brickX) &&
                            (ballX + ballRadius <= brickX)) ||
                        // barely touching from right
                        ((ballX + ballDeltaX - ballRadius <= brickX + brickW) &&
                            (ballX - ballRadius >= brickX + brickW))
                    ) {
                        if ((ballY + ballDeltaY - ballRadius <= brickY + brickH) &&
                            (ballY + ballDeltaY + ballRadius >= brickY)) {
                            // weaken brick and increase score
                            explodeBrick(i, j);

                            bumpedX = true;
                        }
                    }
                }
            }
        }
        return bumpedX;
    }

    function collisionYWithBricks() {
        bumpedY = false;
        for (var i = 0; i < bricks.length; i++) {
            for (var j = 0; j < bricks[i].length; j++) {
                if (bricks[i][j]) {
                    brickX = j * brickW;
                    brickY = i * brickH;
                    if (
                        ((ballY + ballDeltaY - ballRadius <= brickY + brickH) &&
                            (ballY - ballRadius >= brickY + brickH)) ||
                        ((ballY + ballDeltaY + ballRadius >= brickY) &&
                            (ballY + ballRadius <= brickY))) {
                        if (ballX + ballDeltaX + ballRadius >= brickX &&
                            ballX + ballDeltaX - ballRadius <= brickX + brickW) {
                            explodeBrick(i, j);
                            bumpedY = true;
                        }
                    }
                }
            }
        }
        return bumpedY;
    }

    function explodeBrick(i, j) {
        bricks[i][j] = 0;
        nbBrick = countBrick();
        if (nbBrick <= 0) {
            createBricks();
            setTimeout(function () {
                endGame('win');
            }, 500)
        }
    }

    function countBrick() {
        nbBrick = 0
        for (var i = 0; i < bricks.length; i++) {
            for (var j = 0; j < bricks[i].length; j++) {
                if (bricks[i][j] == 1) {
                    nbBrick++;
                }
            }
        }
        return nbBrick;
    }

    $(document).keyup(function (evt) {
        if (evt.keyCode == 32) {
            if (!isStarted) {
                $('#game p').fadeOut(300);
                start();
            } else {

            }
        }
    });


    drawPaddle();
});
